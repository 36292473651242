const kantoTeams = [
  { name: 'ワイルドギース', url: 'http://homepage.mac.com/wildgeese_web/' },
  { name: '横浜シーガル', url: 'http://yseagull.hp.infoseek.co.jp/' },
  { name: 'スティーラーズ', url: 'http://page.freett.com/steelers/' },
  { name: '東京デビルレイズ', url: 'http://www.devilrays.jp/' },
  { name: '世田谷サウスランドレイダース', url: 'http://ssl-rai.web.infoseek.co.jp/' },
  { name: 'ブランディングス', url: 'http://www.rtnet.co.jp/brandings/' },
  { name: 'デイビージョーンズ', url: 'http://www.geocities.jp/davyjones1996/' },
  { name: '川崎グリズリーズ', url: 'http://grizzlies.hp.infoseek.co.jp/' },
  { name: '麻布大学ダックビルズ' },
  { name: '浦和ウラワーズ', url: 'http://ulowers.com/' },
  { name: '鎌倉ラザロ', url: 'http://home.k00.itscom.net/lazarus/' },
  { name: '立川ファルコンズ', url: 'http://falcons.jpn.org/' },
  { name: '横浜タイタンズ', url: 'http://yokohamatitans1968.wix.com/yokohamatitans1968' },
  { name: '東海シーガルズ', url: 'http://www.sepia.dti.ne.jp/seagulls/' },
  { name: '所沢アウトローズ', url: 'http://outlaws.me/' },
  { name: '甲府ユリシーズ', url: 'http://www.geocities.jp/ulysses_yamanashi/' },
]

const nakanihonTeams = [
  { name: '名古屋ブルータス', url: 'http://www5a.biglobe.ne.jp/~brutus/index.htm' },
  { name: '長野ブルーパーズ', url: 'http://homepage2.nifty.com/bloopers/' },
  { name: 'ドラスティックス', url: 'http://toukai.me/drastics/index.html' },
  { name: '新潟ファイティングシャークス', url: 'http://blogs.yahoo.co.jp/niigatasharks' },
  { name: '静岡ファルコンズ', url: 'http://sfalcons.web.fc2.com/' },
]

const kinkiTeams = [
  { name: '奈良ツインズ', url: 'http://db27.fc2web.com/' },
  { name: 'ウイングス', url: 'http://superwings.net/' },
  { name: 'ブートレッグス', url: 'http://bootlegs.boo.jp/' },
  { name: 'リバーサイドジョーカーズ	', url: 'http://jokers1976.com/' },
  { name: '大阪チェックメイト', url: 'http://www.ne.jp/asahi/checkmate/football/' },
  { name: '京都サウスベアーズ', url: 'http://www.geocities.jp/kyotosouthbears/' },
  { name: 'クェーサーズ', url: 'http://www.geocities.co.jp/Athlete-Acropolis/6916/' },
  { name: '同志社ウォーリアーズ	', url: 'http://doshishawarriors.web.fc2.com/' },
  { name: 'アグレッサーズ', url: 'http://aggressors-afc.com/' },
  { name: '大津レイクランダース	', url: 'http://www6.ocn.ne.jp/~lake/' },
  { name: '近大ザルトリウス	', url: 'http://kum30sartorius.web.fc2.com/' },
  { name: 'ポールバニアン', url: 'http://pbunyan.web.fc2.com/' },
  { name: 'マーベリックス', url: 'http://mavericks2009.sakura.ne.jp/index.html' },
]

export { kantoTeams, nakanihonTeams, kinkiTeams }