<template>
  <h4>{{ text }}</h4>
</template>

<style lang='sass' scoped>
h4
  margin: 0.5rem 0
</style>

<script>
export default {
  props: [ 'text' ],
}
</script>
