<template>
  <div id='teams'>
    <PageTitle text='所属チーム' />
    <SectionTitle icon='mdi-account-group' text='東日本支部' />
    <SectionSubTitle text='関東地区' />
    <LinkList :teams='kantoTeams' />
    <SectionTitle icon='mdi-account-group' text='西日本支部' />
    <SectionSubTitle text='中日本地区' />
    <LinkList :teams='nakanihonTeams' />
    <SectionSubTitle text='近畿地区' />
    <LinkList :teams='kinkiTeams' />
    <SectionTitle icon='mdi-account-group' text='友好団体' />
    <SectionSubTitle text='北海道社会人連盟' />
    <LinkList :teams='hokkaidoTeams' />
    <SectionSubTitle text='東北社会人連盟' />
    <LinkList :teams='tohokuTeams' />
    <SectionSubTitle text='青森県アメリカンフットボール連盟'/>
    <LinkList :teams='aomoriTeams' />
    <SectionSubTitle text='北陸社会人連盟'/>
    <LinkList :teams='hokurikuTeams' />
    <SectionSubTitle text='四国社会人連盟'/>
    <LinkList :teams='shikokuTeams' />
    <SectionSubTitle text='九州社会人連盟'/>
    <LinkList :teams='kyusyuTeams' />
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import SectionTitle from '@/components/SectionTitle'
import SectionSubTitle from '@/components/SectionSubTitle'
import LinkList from '@/components/LinkList'

import { kantoTeams, nakanihonTeams, kinkiTeams } from '@/config/teams'
import { hokkaidoTeams, tohokuTeams, aomoriTeams, hokurikuTeams, shikokuTeams, kyusyuTeams } from '@/config/links'

export default {
  components: { PageTitle, SectionTitle, SectionSubTitle, LinkList },
  data () {
    return {
      kantoTeams, nakanihonTeams, kinkiTeams,
      hokkaidoTeams, tohokuTeams, aomoriTeams, hokurikuTeams, shikokuTeams, kyusyuTeams
    }
  },
}
</script>
